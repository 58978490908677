<template>
    <div>
        <div v-if="hasNoInfos">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </div>
        <div v-else-if="horse">
            <a v-if="horse.horse_francegalop" :href="horse.horse_francegalop" target="_blank" rel="noopener noreferrer"  class="btn btn-primary mb-3">
                {{ $t('horse.francegalop') }} <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1" />
            </a>

            <div v-if="horse_pedigree && horse_pedigree.file">
                <div class="container-note note-iframe-info">
                <iframe :src="horse_pedigree.file.files_s3_url" />
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js"

	export default {
		name: 'InformationsHorse',
		mixins: [HorseMixin],
		props:[
			'horse_id'
		],
		data (){
			return {
				horse: null,
				horse_pedigree: null,
                loading: true
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.horse = await this.getHorseById(this.horse_id)
				await this.loadPedigree()
                this.loading = false
			},
			async loadPedigree() {
				this.horse_pedigree = await this.getHorsePedigree(this.horse_id)
			}
		},
        computed: {
            hasNoInfos() {
                return !this.loading 
                    && (!this.horse || !this.horse.horse_francegalop) 
                    && (!this.horse_pedigree || !this.horse_pedigree.file)
            }
        },
		watch: {
			'horse_id' (val) {
				this.init_component()	
			}
		}
	}
</script>